import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {deploymentService, deviceService, managementService} from '_services'
import {dangerColor, primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import Button from "../../components/CustomButtons";
import Table from "../../components/Table";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import queryString from "query-string";
import {deviceFleetActions, sweetAlertActions} from "../../_actions";
import CustomInput from "../../components/CustomInput";
import {lowercaseWithSpaces} from "../helpers";
import MyDropdown from "../../components/MyDropdown";
import {CheckTwoTone, EditTwoTone} from "@material-ui/icons";


class DeviceInformation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      device_info: {},
      deploy_device: false,
      all_device_types: {},
      edit_metadata: false,
      device_credentials: {
        "created_at": "***",
        "created_by": "***",
        "algorithm_type": "***",
      },
      updated_metadata: {},
      algorithm_type: "RSA2048",
      algorithm_types: [],
      all_life_cycle_stages: [],
      selected_stage: null,
      name: null,
      client_name: null,
      is_updating_life_cycle_stage: false,
    }
    this._is_mounted = false
  }

  componentDidMount() {
    this._is_mounted = true

    managementService.getAllLifeCycleStages(res => {
      this.setState({
        all_life_cycle_stages: res.result.map((stage) => {
          return {"id": stage["id"], "name": stage["id"]}
        })
      })
    })

    let {device_serial} = queryString.parse(this.props.location.search)
    let my_device_serial = this.props.device_serial
    if (device_serial !== undefined && device_serial !== null) {
      this.props.dispatch(deviceFleetActions.updateSerial(device_serial))
      my_device_serial = device_serial
    }
    this.refresh(my_device_serial)
  }

  set_life_cycle_stage() {
    this.setState({is_updating_life_cycle_stage: true})
    const {device_serial} = this.props
    const {selected_stage} = this.state
    managementService.updateDevice(device_serial, null, null, selected_stage, null, res => {
      this.refresh(device_serial)
      this.setState({
        selected_stage: null,
        is_updating_life_cycle_stage: false
      })
    })
  }

  update_name() {
    const {device_serial} = this.props
    const {name} = this.state
    managementService.updateDevice(device_serial, name, null, null, null, res => {
      this.refresh(device_serial)
      this.setState({
        name: null
      })
    })
  }

  update_client_name() {
    const {device_serial} = this.props
    const {client_name} = this.state
    managementService.updateDevice(device_serial, null, client_name, null, null, res => {
      this.refresh(device_serial)
      this.setState({
        client_name: null
      })
    })
  }

  cancel() {
    this.setState({
      selected_stage: null,
      name: null,
      client_name: null
    })
  }

  componentWillUnmount() {
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
  }

  handle_form_input_selector(name, value) {
    this.setState({[name]: value})
  }

  edit(stage) {
    this.setState({
      selected_stage: stage
    })
  }

  refresh(device_serial) {
    if (device_serial) {
      deviceService.getDevice(device_serial, res => {
        this.setState({device_info: res})
      })
    }

    managementService.getAllDeviceTypes(res => {
      let all_device_types = {}

      res.result.forEach((device_type) => {
        all_device_types[device_type.id] = device_type
      })

      this.setState({all_device_types})
    })
  }

  get_access_token() {
    const {device_serial} = this.props
    deviceService.getAccessToken(device_serial, res => {
    })
  }

  get_qr_code() {
    const {device_serial} = this.props
    deviceService.getQRCode(device_serial, res => {
    })
  }

  get_credentials() {
    const {device_serial} = this.props
    deviceService.getCredentials(device_serial, res => {
      this.setState({device_credentials: res.credentials})
    })
    const {device_info, all_device_types} = this.state
    let credential_options = []
    let device_type = undefined
    if (device_info) {
      device_type = all_device_types[device_info["device_type"]]
    }
    switch (device_type ? device_type["id"] : "None") {
      case "LUMION_FIX":
        credential_options = [
          {id: "RSA2048", value: "RSA2048"}
        ]
        break;
      case "DIV01":
      case "XENA6":
      case "XENA7":
        credential_options = [
          {id: "NISTP256", value: "NISTP256"}
        ]
        break;
      case "UVP01":
      case "UVP02":
      case "UVP03":
      case "SPR01":
      case "SCT01":
      case "DSS01":
      case "DIL01":
      case "DBD01":
      case "BUG01":
        credential_options = [
          {id: "IDENTITY", value: "Device Identity"}
        ]
        break;
      default:
        console.log("unspecified device_type, showing all options for:", device_type ? device_type["id"] : "None")
        credential_options = [
          {id: "RSA2048", value: "RSA2048"},
          {id: "NISTP256", value: "NISTP256"},
          {id: "IDENTITY", value: "Device Identity"}
        ]
    }
    this.setState({algorithm_types: credential_options, algorithm_type: credential_options[0].id})
  }

  generate_credentials() {
    const {device_serial} = this.props
    const {algorithm_type} = this.state
    managementService.generateDeviceCredentials(device_serial, algorithm_type, res => {
      this.get_credentials()
    })
  }

  change_value(config_name, value) {
    this.setState({[config_name]: value})
  }

  change_metadata_value(config_name, value) {
    this.setState(prevState => {
      return {
        ...prevState,
        updated_metadata: {
          ...prevState.updated_metadata,
          [config_name]: value
        }
      }
    })
  }

  confirm_delete_credentials() {
    const {dispatch} = this.props
    dispatch(sweetAlertActions.clean({
      title: "Are you sure?",
      showCancel: true,
      onCancel: this.clear_popup.bind(this),
      cancelBtnText: "No",
      onConfirm: this.delete_credentials.bind(this),
      confirmBtnText: "Yes",
      content: 'This action cannot be undone, do not do this unless you know what you are doing.'
    }))
  }

  clear_popup() {
    const {dispatch} = this.props;
    dispatch(sweetAlertActions.clear())
  }

  delete_credentials() {
    const {device_serial} = this.props
    managementService.deleteDeviceCredentials(device_serial, res => {
      this.clear_popup()
      this.get_credentials()
    })
  }

  start_deploy_at_location() {
    this.setState({deploy_device: true})
  }

  deploy_at_location() {
    const {location_id} = this.state
    const {dispatch, device_serial} = this.props;

    deviceService.deployDevice(device_serial, location_id, res => {
      dispatch(sweetAlertActions.success({title: "Device is deployed"}))

      this.setState({
        location_id: null,
        deploy_device: false,
      }, () => this.refresh(device_serial))
    })
  }


  start_close_deployment(deployment_id) {
    const {dispatch} = this.props

    dispatch(sweetAlertActions.clean({
      title: "Are you sure?",
      showCancel: true,
      onCancel: this.clear_popup.bind(this),
      cancelBtnText: "No",
      onConfirm: this.close_deployment.bind(this, deployment_id),
      confirmBtnText: "Yes",
      content: <div>
        Do you really want to close this deployment? This cannot be undone.
      </div>
    }))
  }

  close_deployment(deployment_id) {
    const {dispatch, device_serial} = this.props;

    deploymentService.closeDeployment(deployment_id, res => {
      dispatch(sweetAlertActions.clear())
      this.refresh(device_serial)
    })
  }

  edit_metadata() {
    const {device_serial} = this.props
    deviceService.getDeviceDecrypted(device_serial, res => {
      let updated_metadata = {}
      if (res["metadata"]) {
        updated_metadata = res["metadata"]
      }
      this.setState({device_info: res, edit_metadata: true, updated_metadata})
    })
  }

  get_decrypted_metadata() {
    const {device_serial} = this.props
    deviceService.getDeviceDecrypted(device_serial, res => {
      this.setState({device_info: res})
    })
  }

  update_metadata() {
    const {device_serial} = this.props
    const {device_info, updated_metadata} = this.state

    if (device_info["metadata"] !== updated_metadata) {
      managementService.updateDevice(device_serial, null, null, null, updated_metadata, res => {
        this.setState({edit_metadata: false, updated_metadata: {}}, () => {
          this.refresh(device_serial)
        })
      })
    } else {
      this.setState({edit_metadata: false, updated_metadata: {}})
    }
  }

  render() {
    const {device_name, device_serial, locations, nimbus_manager} = this.props
    const {
      device_info,
      device_credentials,
      algorithm_type,
      algorithm_types,
      all_life_cycle_stages,
      is_updating_life_cycle_stage,
      selected_stage,
      all_device_types,
      edit_metadata,
      client_name,
      name,
      updated_metadata,
      deploy_device
    } = this.state;

    let metadata_table = []
    let device_type = undefined
    if (device_info) {
      device_type = all_device_types[device_info["device_type"]]
    }
    if (device_type && device_type["metadata_schema"]) {
      if (device_type["id"] === "DIV01") {
        metadata_table = [
          [
            <b>Robot Platform ID</b>,
            "",
            edit_metadata ?
              <CustomInput
                labelText={"robot_platform_id"}
                inputProps={{
                  onChange: event => this.change_metadata_value("robot_platform_id", event.target.value),
                  type: "text",
                  value: updated_metadata["robot_platform_id"]
                }}
              />
              :
              device_info["metadata"] ? device_info["metadata"]["robot_platform_id"] : "",
            ""
          ],
          [
            <b>Access Secret</b>,
            "",
            edit_metadata ?
              <CustomInput
                labelText={"Access Secret"}
                inputProps={{
                  onChange: event => this.change_metadata_value("access_secret", {
                    "value": event.target.value,
                  }),
                  type: "text",
                  value: updated_metadata["access_secret"] ? updated_metadata["access_secret"]["value"] : ""
                }}
              />
              :
              device_info["metadata"] ? device_info["metadata"]["access_secret"] ? device_info["metadata"]["access_secret"]["value"] : "" : "",
            ""
          ],
          [
            <b>Platform WiFi Credentials</b>,
            <b>WiFi SSID</b>,
            edit_metadata ?
              <CustomInput
                labelText={"WiFi SSID"}
                inputProps={{
                  onChange: event => this.change_metadata_value("platform_wifi_credentials", {
                    "wifi_ssid": event.target.value,
                    "wifi_password": updated_metadata["platform_wifi_credentials"] ? updated_metadata["platform_wifi_credentials"]["wifi_password"] : ""
                  }),
                  type: "text",
                  value: updated_metadata["platform_wifi_credentials"] ? updated_metadata["platform_wifi_credentials"]["wifi_ssid"] : ""
                }}
              />
              :
              device_info["metadata"] ? device_info["metadata"]["platform_wifi_credentials"] ? device_info["metadata"]["platform_wifi_credentials"]["wifi_ssid"] : "" : "",
            ""
          ],
          [
            <b>Platform WiFi Credentials</b>,
            <b>WiFi Password</b>,
            edit_metadata ?
              <CustomInput
                labelText={"WiFi Password"}
                inputProps={{
                  onChange: event => this.change_metadata_value("platform_wifi_credentials", {
                    "wifi_password": event.target.value,
                    "wifi_ssid": updated_metadata["platform_wifi_credentials"] ? updated_metadata["platform_wifi_credentials"]["wifi_ssid"] : ""
                  }),
                  type: "text",
                  value: updated_metadata["platform_wifi_credentials"] ? updated_metadata["platform_wifi_credentials"]["wifi_password"] : ""
                }}
              />
              :
              device_info["metadata"] ? device_info["metadata"]["platform_wifi_credentials"] ? device_info["metadata"]["platform_wifi_credentials"]["wifi_password"] : "" : "",
            ""
          ],
        ]
      }

      if (device_type["id"] === "LUMION_FIX") {
        metadata_table = [
          [
            <b>App Login Credentials</b>,
            <b>Username</b>,
            edit_metadata ?
              <CustomInput
                labelText={"Username"}
                inputProps={{
                  onChange: event => this.change_metadata_value("app_login_credentials", {
                    "username": event.target.value,
                    "password": updated_metadata["app_login_credentials"] ? updated_metadata["app_login_credentials"]["password"] : ""
                  }),
                  type: "text",
                  value: updated_metadata["app_login_credentials"] ? updated_metadata["app_login_credentials"]["username"] : ""
                }}
              />
              :
              device_info["metadata"] ? device_info["metadata"]["app_login_credentials"] ? device_info["metadata"]["app_login_credentials"]["username"] : "" : "",
            ""
          ],
          [
            <b>App Login Credentials</b>,
            <b>Password</b>,
            edit_metadata ?
              <CustomInput
                labelText={"Password"}
                inputProps={{
                  onChange: event => this.change_metadata_value("app_login_credentials", {
                    "password": event.target.value,
                    "username": updated_metadata["app_login_credentials"] ? updated_metadata["app_login_credentials"]["username"] : ""
                  }),
                  type: "text",
                  value: updated_metadata["app_login_credentials"] ? updated_metadata["app_login_credentials"]["password"] : ""
                }}
              />
              :
              device_info["metadata"] ? device_info["metadata"]["app_login_credentials"] ? device_info["metadata"]["app_login_credentials"]["password"] : "" : "",
            ""
          ],
          [
            <b>Platform WiFi Credentials</b>,
            <b>WiFi SSID</b>,
            edit_metadata ?
              <CustomInput
                labelText={"WiFi SSID"}
                inputProps={{
                  onChange: event => this.change_metadata_value("platform_wifi_credentials", {
                    "wifi_ssid": event.target.value,
                    "wifi_password": updated_metadata["platform_wifi_credentials"] ? updated_metadata["platform_wifi_credentials"]["wifi_password"] : ""
                  }),
                  type: "text",
                  value: updated_metadata["platform_wifi_credentials"] ? updated_metadata["platform_wifi_credentials"]["wifi_ssid"] : ""
                }}
              />
              :
              device_info["metadata"] ? device_info["metadata"]["platform_wifi_credentials"] ? device_info["metadata"]["platform_wifi_credentials"]["wifi_ssid"] : "" : "",
            ""
          ],
          [
            <b>Platform WiFi Credentials</b>,
            <b>WiFi Password</b>,
            edit_metadata ?
              <CustomInput
                labelText={"WiFi Password"}
                inputProps={{
                  onChange: event => this.change_metadata_value("platform_wifi_credentials", {
                    "wifi_password": event.target.value,
                    "wifi_ssid": updated_metadata["platform_wifi_credentials"] ? updated_metadata["platform_wifi_credentials"]["wifi_ssid"] : ""
                  }),
                  type: "text",
                  value: updated_metadata["platform_wifi_credentials"] ? updated_metadata["platform_wifi_credentials"]["wifi_password"] : ""
                }}
              />
              :
              device_info["metadata"] ? device_info["metadata"]["platform_wifi_credentials"] ? device_info["metadata"]["platform_wifi_credentials"]["wifi_password"] : "" : "",
            ""
          ],
        ]
      }
    }

    let active_deployment_location_name = ""
    if (device_info && device_info["active_deployment"]) {
      if (device_info["active_deployment"]["location_id"]) {
        active_deployment_location_name = device_info["active_deployment"]["location_id"]
        if (locations && locations[device_info["active_deployment"]["location_id"]]) {
          active_deployment_location_name = locations[device_info["active_deployment"]["location_id"]]["name"]
        }
      }
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>{device_name}</b><Button color={"primary"}
                                                                                      simple
                                                                                      onClick={this.refresh.bind(this, device_serial)}>Refresh</Button>
            </h4></CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <Link to={`/management/overview`}>Back To Overview </Link>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <h5 style={{color: primaryColor}}><b>Device Information</b></h5>
                  <Table
                    tableHeaderColor="primary"
                    style={{fontSize: '10px'}}
                    tableData={[
                      [
                        <b>Serial Number</b>,
                        device_serial,
                        ""
                      ],
                      [
                        <b>Name</b>,
                        <div>
                          {name === null &&
                            <p>{device_info["name"]}</p>
                          }
                          {name !== null &&
                            <CustomInput
                              labelText={"Name"}
                              fullWidth={true}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: event => this.handle_form_input_selector("name", event.target.value),
                                type: "text",
                                fullWidth: true
                              }}
                            />
                          }
                        </div>,


                        <div>
                          {name === null &&
                            <Button
                              simple
                              color={"primary"} onClick={
                              this.handle_form_input_selector.bind(this, "name", device_info["name"])
                            }> Edit </Button>
                          }
                          {name !== null &&
                            <div>
                              <Button simple
                                      color={"primary"}
                                      onClick={this.update_name.bind(this)}> Confirm </Button>
                              <Button simple
                                      color={"danger"}
                                      onClick={this.cancel.bind(this)}> Cancel </Button>
                            </div>
                          }
                        </div>
                      ],
                      [
                        <b>Client Name</b>,
                        <div>
                          {client_name === null &&
                            <p>{device_info["client_name"]}</p>
                          }
                          {client_name !== null &&
                            <CustomInput
                              labelText={"Client Name"}
                              fullWidth={true}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: event => this.handle_form_input_selector("client_name", event.target.value),
                                type: "text",
                                fullWidth: true
                              }}
                            />
                          }
                        </div>,


                        <div>
                          {client_name === null &&
                            <Button
                              simple
                              color={"primary"} onClick={
                              this.handle_form_input_selector.bind(this, "client_name", device_info["client_name"])
                            }> Edit </Button>
                          }
                          {client_name !== null &&
                            <div>
                              <Button simple
                                      color={"primary"}
                                      onClick={this.update_client_name.bind(this)}> Confirm </Button>
                              <Button simple
                                      color={"danger"}
                                      onClick={this.cancel.bind(this)}> Cancel </Button>
                            </div>
                          }
                        </div>
                      ],

                      [
                        <b>Device Type</b>,
                        all_device_types[device_info["device_type"]] ? all_device_types[device_info["device_type"]]["name"] : "",
                        ""
                      ],
                      [
                        <b>Life Cycle Stage</b>,
                        <div>
                          {selected_stage === null &&
                            <p>{lowercaseWithSpaces(device_info["life_cycle_stage"])}</p>
                          }
                          {selected_stage !== null &&
                            <MyDropdown
                              input_label={"Life Cycle Stage"}
                              name={"selected_stage"}
                              value={this.state["selected_stage"]}
                              default_value={this.state["selected_stage"]}
                              options={[...all_life_cycle_stages.map((item) => {
                                return {
                                  id: item.id, value: lowercaseWithSpaces(item.name)
                                }

                              })]}
                              handle_selection={this.handle_form_input_selector.bind(this)}
                            />
                          }
                        </div>,
                        <div>
                          {selected_stage === null &&
                            <Button
                              simple
                              disabled={is_updating_life_cycle_stage}
                              color={"primary"} onClick={
                              this.handle_form_input_selector.bind(this, "selected_stage", device_info["life_cycle_stage"])
                            }> Edit </Button>
                          }
                          {selected_stage !== null &&
                            <div>
                              <Button simple disabled={is_updating_life_cycle_stage}
                                      color={"primary"}
                                      onClick={this.set_life_cycle_stage.bind(this)}> Confirm </Button>
                              <Button simple disabled={is_updating_life_cycle_stage}
                                      color={"danger"}
                                      onClick={this.cancel.bind(this)}> Cancel </Button>
                            </div>
                          }
                        </div>
                      ],
                      [
                        <b>First Seen</b>,
                        new Date(device_info["first_seen"]).toLocaleString("nl-BE"),
                        ""
                      ],
                      ["", "", ""]
                    ]}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <h5 style={{color: primaryColor}}><b>Extra</b></h5>
                  <Table
                    tableHeaderColor="primary"
                    style={{fontSize: '10px'}}
                    tableData={
                      [
                        [
                          "Get Access Token",
                          <Button
                            color={"primary"}
                            simple
                            onClick={this.get_access_token.bind(this)}
                          >
                            Get Access Token
                          </Button>
                        ],
                        [
                          "Download QR code",
                          <Button
                            color={"primary"}
                            simple
                            onClick={this.get_qr_code.bind(this)}
                          >
                            Download QR
                          </Button>
                        ],
                        ["", ""]
                      ]}
                  />
                  <h5 style={{color: device_info["metadata_valid"] ? primaryColor : dangerColor}}>
                    <b>Metadata</b>
                    {!edit_metadata && <Button justIcon simple color={"primary"}
                                               onClick={this.edit_metadata.bind(this)}><EditTwoTone/></Button>}
                    {edit_metadata && <Button justIcon simple color={"primary"}
                                              onClick={this.update_metadata.bind(this)}><CheckTwoTone/></Button>}
                    <Button simple color={"primary"} onClick={this.get_decrypted_metadata.bind(this)}>Decrypt
                      Data</Button>
                  </h5>
                  <Table
                    tableHeaderColor="primary"
                    style={{fontSize: '10px'}}
                    tableData={[
                      ...metadata_table,
                      ["", "", "", ""]
                    ]}
                  />
                  <h5 style={{color: primaryColor}}><b>Active Deployment</b></h5>
                  {deploy_device ?
                    <div>
                      <MyDropdown
                        input_label={"Location"}
                        name={"location_id"}
                        value={this.state["location_id"]}
                        default_value={this.state["location_id"]}
                        options={[...Object.keys(locations).sort((a, b) => {
                          return locations[a]["name"] < locations[b]["name"] ? -1 : 1
                        }).map((location_id) => {
                          return {
                            id: location_id, value: locations[location_id]["name"]
                          }

                        })]}
                        handle_selection={this.handle_form_input_selector.bind(this)}
                      />
                      <Button simple color={"primary"} onClick={this.deploy_at_location.bind(this)}> Deploy </Button>
                      <Button simple color={"danger"} onClick={() => {
                        this.setState({deploy_device: false, location_id: null})
                      }}> Cancel </Button>
                    </div> :
                    <div>
                      <Table
                        tableHeaderColor="primary"
                        style={{fontSize: '10px'}}
                        tableData={[
                          [
                            <b>Deployed at</b>,
                            device_info["active_deployment"] ?
                              active_deployment_location_name
                              :
                              "Not Deployed",
                            device_info["active_deployment"] ?
                              <Button
                                onClick={this.start_close_deployment.bind(this, device_info["active_deployment"]["id"])}
                                color={"danger"} simple>Close Deployment</Button>
                              :
                              <Button onClick={this.start_deploy_at_location.bind(this)}
                                      color={"primary"} simple>Deploy</Button>
                          ],
                          [
                            <b>Creation Date</b>,
                            device_info["active_deployment"] ?
                              new Date(device_info["active_deployment"]["creation_date"]).toLocaleString("nl-BE")
                              :
                              "",
                            ""
                          ],
                          ["", "", ""]
                        ]}
                      />
                    </div>
                  }
                </GridItem>
                <GridItem xs={12} md={6}>
                  <h5 style={{color: primaryColor}}><b>Credentials</b></h5>
                  <Button onClick={this.get_credentials.bind(this)} color={"primary"} simple>Request
                    Credentials</Button>
                  Every query is logged
                  {device_credentials &&
                    <Table
                      tableHeaderColor="primary"
                      style={{fontSize: '10px'}}
                      tableData={[
                        [
                          <b>Created At</b>,
                          device_credentials["created_at"]
                        ],
                        [
                          <b>Created By</b>,
                          device_credentials["created_by"]
                        ],
                        [
                          <b>Algorithm Type</b>,
                          device_credentials["algorithm_type"]
                        ],
                        [
                          <b>Delete Credentials</b>,
                          nimbus_manager ?
                            device_credentials["created_at"] !== "***" ?
                              <Button onClick={this.confirm_delete_credentials.bind(this)}
                                      color={"danger"}
                                      simple>Delete Credentials</Button> :
                              ""
                            :
                            "Ask a cloud manager to delete these credentials if necessary"
                        ],
                        ["", ""]
                      ]}
                    />
                  }
                  {!device_credentials &&
                    <div>
                      {algorithm_types.length <= 1 ?
                        <h5 style={{fontWeight: "medium"}}>Algorithm type: <b>{algorithm_type}</b></h5>
                        :
                        <MyDropdown
                          input_label={"Algorithm Type"}
                          name={"algorithm_type"}
                          value={algorithm_type}
                          default_value={algorithm_type}
                          options={algorithm_types}
                          classes={algorithm_types.length <= 1 ? "disabled" : ""}
                          handle_selection={this.change_value.bind(this)}
                        />
                      }

                      <Button onClick={this.generate_credentials.bind(this)} color={"primary"}>Generate
                        Credentials</Button>
                    </div>
                  }
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {device_serial, all_devices} = state.device_fleet
  const {locations} = state.location
  const {nimbus_manager} = state.authentication.user

  let device_name = ""
  all_devices.forEach((device)=>{
    if (device["serial"] === device_serial){
      device_name = device["name"]
    }
  })

  return {
    device_serial, device_name, locations, nimbus_manager
  }
}


export default withStyles(userProfileStyles)(connect(mapStateToProps)(DeviceInformation))
